import React, { useState, useEffect } from "react";

import MaisAtacado from "../../Services/MaisAtacado/MaisAtacado";

import Popup from "../../components/Popup/Popup";
import HomeNPS from "./Components/HomeNPS/HomeNPS";
import BasePage from "../../components/BasePage/BasePage";
import SideMenu from "../../components/SideMenu/SideMenu";
import HomeInfoConteiner from "./Components/HomeInfoContainer/HomeInfoContainer";

import BackgroundImageDesktop from "../../assets/images/Background/novoRecebimentoDigitalDesktop.svg";
import BackgroundImageMobile from "../../assets/images/Background/novoRecebimentoDigitalMobile.svg";

import "./Home.css";
import Modal from "../../components/Modal/Modal";
import useAffiliate from "../../Hooks/AffiliateContext";


const Home = () => {
  const [open, setOpen] = useState(true);
  const [NPS, setNPS] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { affiliate, acceptedNewContract } = useAffiliate();

  const isMobile = window.screen.width < 830;

  // useEffect(() => {
  //   if (!userAnswered) {
  //     MaisAtacado.get("v1/nps/research/1")
  //       .then((res) => {
  //         setNPS(res.data);
  //         if (res.data?.Questions[0]?.Answer) {
  //           localStorage.setItem("userAnswered", true);
  //           setOpen(true);
  //         }
  //       })
  //       .catch((err) => setOpen(true));
  //   } else {
  //     setOpen(true);
  //   }
  // }, [userAnswered]);

  const handleSendNPS = (answer) => {
    MaisAtacado.post("v1/nps/answer", answer).then(() => {
      setIsSent(true);
    });
  };

  const handleCloseNPS = () => {
    setNPS(null);
    localStorage.setItem("userAnswered", true);
  };
  const dadosCadastrais = document.getElementById("afiliadosDADOS CADASTRAIS")
  const propsModal = {
    modalName: "ATUALIZAÇÕES NO CONTRATO AFILIADOS",
    closeName: "VER DEPOIS",
    onClose: () => setOpenModal(false),
    actionName: "ASSINAR CONTRATO",
    open: openModal,
    action: () => dadosCadastrais.click()
  }

  useEffect(() => {
    if (affiliate && !acceptedNewContract) setOpenModal(true)
    else setOpenModal(false)
  }, [affiliate, acceptedNewContract]);


  return (
    <BasePage>
      {NPS && !NPS?.Questions[0]?.Answer && (
        <HomeNPS
          nps={NPS}
          isSent={isSent}
          onSend={handleSendNPS}
          onClose={handleCloseNPS}
        />
      )}
      <Modal {...propsModal}>
        <div>
          Modificamos alguns detalhes no contrato.
          Por favor, atualize sua assinatura para
          garantir que esteja sempre por dentro das mudanças.
        </div>
      </Modal>
      <Popup
        open={open}
        PopupName=""
        onClose={() => setOpen(false)}
        width={isMobile ? "80vw" :"50vw"}
      >
        <button
          className={isMobile ? "button__popup_saiba-mais-mobile" : "button__popup_saiba-mais"}
          onClick={() =>window.location.replace("/Comercial/recebimento/")}
        />
        <img
          data-testid="infos"
          src={isMobile ? BackgroundImageMobile : BackgroundImageDesktop}
          alt="Informações sobre o mais lojinha"
          style={{ width: "100%" }}
        />
      </Popup> 

      <main data-testid="home" className="main__home--container">
        <SideMenu />
        <HomeInfoConteiner />
      </main>
    </BasePage>
  );
};

export default Home;
