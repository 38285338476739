import React from "react";

import FeatureList from "./FeatureList";
import SocialMediaList from "./SocialMediaList";

import "./index.css";

const Feature = ({ lojinhaItems, socialMediaItems }) => {
  return (
    <section data-testid="featureHome" className="section__feature--container">
      <FeatureList lojinhaItems={lojinhaItems} />
      <SocialMediaList socialMediaItems={socialMediaItems} />
      <p>
        Você também pode acompanhar, em tempo real o número de visitas recebidos
        para cada lojinha.
      </p>
    </section>
  );
};

export default Feature;
