import React from "react";
import {
  TableFooter,
  TablePagination,
  TableRow,
  Table,
} from "@mui/material";
import SomaTableTitle from "./SomaTableTitle/SomaTableTitle";
import SomaTableBody from "./SomaTableBody/SomaTableBody";
import "./SomaTable.css";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

const SomaTable = (props) => {
  const isDefined = (val) => val !== null && val !== undefined;

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };

  return props.results ? (
    <>
      <div className="div__somatable--conteiner">
        <table className="table__somatable--conteiner">
          <SomaTableTitle
            titles={props.columns[0].componentProps.colunas}
            hasButton={props.hasButton}
          />
          <SomaTableBody
            data={props.results}
            checked={props.optionSelectedSingle}
            hasButton={props.hasButton}
            columns={props.columns[0].componentProps.colunas}
            infoLine={props.columns[0].componentProps.infoList}
            lineHighlighted={props.columns[0].lineHighlighted}
            highlightedColor={props.columns[0].highlightedColor}
          />
        </table>
      </div>
      <Table className="div__tablefooter--conteiner">
        {[
          props.total,
          props.rowsPerPage,
          props.page,
          props.setRowsPerPage,
          props.setPage,
        ].reduce((a, b) => a && isDefined(b)) ? (
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={props.total}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                labelDisplayedRows = {props?.labelDisplayedRows}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        ) : null}
      </Table>
    </>
  ) : null;
};

export default SomaTable;
