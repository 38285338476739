import React from "react";

import Phone_lojinha from "../../../../../../assets/images/+Lojinha/phone_lojinha.svg";
import Phone_lojinha_mobile from "../../../../../../assets/images/+Lojinha/mobile_phone.svg";

import "./index.css";

const DoYouKnow = () => {
  return (
    <section className="section__doyouknow--container">
      <div className="div__doyouknow--text">
        <h4>TEMOS NOVIDADES FRESQUINHAS!</h4>
        <h2>VOCÊ JÁ CONHECE O</h2>
        <span>+LOJINHA?</span>
        <p>A SUA VITRINE VIRTUAL DAS MARCAS GRUPO SOMA</p>
      </div>
      <div style={{ zIndex: "1" }}>
        <img className="img__phone" data-testid="img__phone" src={Phone_lojinha} alt="Celular na tela do +Lojinha" />
        <img className="img__phone--mobile" data-testid="img__phone--mobile" src={Phone_lojinha_mobile} alt="Celular na tela do +Lojinha" />
      </div>
    </section>
  );
};

export default DoYouKnow;
