import React, { useEffect, useState } from 'react'
import '../../../Comercial/Components/FormMultiStepRecebimento/Nfe.css'

import useDivergenciaReducer from "../../../../Hooks/useDivergenciaReducer.js";

import { ReactComponent as Warning } from '../../../../assets/images/icons/warning.svg'
import { ReactComponent as UpdateStatus } from '../../../../assets/images/icons/updateStatus.svg'
import { ReactComponent as SendMessage } from '../../../../assets/images/icons/enviarMensagem.svg'

import CardBox from '../../../../components/CardBox/Card.js';
import DetailsBox from '../../../../components/DetailsBox/DetailsBox.js';
import ButtonBack from '../../../Comercial/Components/FormMultiStepRecebimento/ButtonBack.js';
import DivergencyTag from '../../../../components/Tag/Tag.js';
import FooterButton from '../../../../components/FooterButton/footerButton.js';
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton.js';
import Historic from '../../../../components/Historic/Historic.js';
import Modal from '../../../../components/Modal/Modal';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import Status from '../../../../Utils/models/Status.js';
import Spinner from '../../../../components/Spinner/Spinner.js';
import ConfirmCredentialsModal from '../../../../components/ConfirmCredentialsModal/ConfirmCredentialsModal.js';
import SomaTextEditor from '../../../../components/SomaStandard/SomaTextEditor/SomaTextEditor.js';

const DivergenciasEntrega = () => {
    const { state, voltar, salvarMensagem, download, getEtiquetaDevolucao, atualizarStatus, finalizarAtendimento } = useDivergenciaReducer();
    const [openModalFinalizar, setOpenModalFinalizar] = useState(false)
    const [messageInfo, setMessageInfo] = useState('');
    const [textMessage, setTextMessage] = useState('')
    const [modalState, setModalState] = useState('none');
    const [divergeciaStatus, setDivergeciaStatus] = useState({ status: 7, produto: '', gradeItem: '', qtde: '', cor: '', tipo: '' })

    useEffect(() => {
        try {
            const mainTitle = document.getElementById('title')
            mainTitle.style.display = 'none'

        } catch (error) {
            console.error(error)
        }
    }, [])

    if (!state.data) return <></>

    const handleChange = (event) => {
        const { value } = event.target;
        setDivergeciaStatus(prev => ({ ...prev, status: parseInt(value) }));
    };

    const createAutoMessage = (msg, author, date) => {
        return {
            button: () => {
                setTextMessage(msg)
                setMessageInfo({
                    author: author,
                    date: `${new Date(date).toLocaleDateString('pt-br')}`
                })
                setModalState("showMessage");
            },
            buttonText: 'Ver mensagem'
        }
    };

    const finishDivergency = () => {
        setOpenModalFinalizar(true)
    }

    const divergencyTagContent = () => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {state.data.divergenciaTag.includes("falta") && (<DivergencyTag name="Falta" color="#ED8E00" />)}
            {state.data.divergenciaTag.some(tag => ["extra", "sobra"].includes(tag)) && (<DivergencyTag name="Sobra" color="#2596DA" />)}
        </div>
    )

    const propsCardBox = {
        title: { name: 'N° de embarque', id: state.data.entrega },
        infos: [
            { left: 'Divergência', right: divergencyTagContent() },
            { left: 'Clifor', right: state.data.clifor },
            { left: 'Cliente', right: state.data.cliente },
            { left: 'Data do apontamento', right: new Date(state.data.dataApontamento).toLocaleDateString('pt-br') },
            { left: 'Colaborador Responsável', right: state.data.atendente },
            { left: 'Status', right: state.data.lockedAt ? 'Finalizado' : 'Em Andamento' },
        ],
    }

    const list = {
        name: 'DIVERGÊNCIAS',
        items: state.data.tratativas.map(item => {
            return {
                isSearchPage: true,
                title: {
                    id: `Produto: ${item.produto.trim()}`,
                    description: item.desc,
                    Icons: [
                        item?.StatusGradeItem.type === "falta" && <DivergencyTag name="Falta" color="#ED8E00" />,
                        item?.StatusGradeItem.type === "sobra" && <DivergencyTag name="Sobra" color="#2596DA" />,
                    ],
                    key: item.produto.trim() + item.pedido + item.cor + item.gradeItem,
                },
                blocksInfos: {
                    block1: [
                        [{ key: 'Status: ', value: item.Status.type }],
                    ],
                    block2: [
                        [
                            { key: 'Marca: ', value: item.descMarca },
                            { key: 'pedido: ', value: item.pedido },
                        ],
                        [
                            { key: 'Tam: ', value: item.gradeItem },
                            { key: 'Nota fiscal: ', value: item.nfSaida },
                        ],
                        [
                            {
                                key: 'Qtde: ',
                                value: item.qtde
                            },
                            { key: 'Caixa: ', value: item.caixa },
                        ],
                        [
                            { key: 'Cor: ', value: item.cor, },

                        ],

                    ],
                    block3: [
                        [
                            item.StatusGradeItem.type === "sobra" &&
                            { key: item.querPeca ? 'Faturar ' : 'Devolver', value: 'produto' },
                            item.StatusGradeItem.type === "falta" &&
                            { key: 'Caixa/Lacre violado? ', value: item.caixaViolada ? 'Sim' : 'Não' },
                        ],
                    ],
                },
                grid: null,
                divergency: null,
                gridReducer: null,
                componentProps: item?.newUpdate ? { additionalClassName: 'itemInfo_highlighted' } : {},
                editItemButton: !state.data.lockedAt && {
                    text: "Atualizar status",
                    Icon: <UpdateStatus />,
                    click: () => {
                        setDivergeciaStatus(prev => ({
                            ...prev,
                            produto: item.produto,
                            gradeItem: item.gradeItem,
                            qtde: item.qtde,
                            cor: item.cor,
                            tipo: item.StatusGradeItem.type
                        }))
                        setModalState("divergenciaStatus");
                    },
                    componentProps: { additionalClassName: 'divergencyButtonUpdate' }
                },

            }
        })
    };

    const propsHistoric = {
        title: { name: 'Histórico do Atendimento' },
        infos: state.data.historico.map(h => {
            if (h?.valorAtual) {
                const van = JSON.parse(h.valorAnterior)
                const vat = JSON.parse(h.valorAtual)
                const prod = state.data.tratativas.find(
                    p => p.produto.trim() === vat.produto && p.gradeItem === vat.gradeItem)
                const san = Status.find(s => s.id_status === van.status).type
                const statusAtual = Status.find(s => s.id_status === vat.status)
                const sat = statusAtual.type
                const s = { style: { fontFamily: 'flama-semibold', color: '#3A3A40' } }
                const downloadFileType = vat.status === 17 ? 'png' : 'pdf';
                const downloadText = vat.status === 17 ? 'Baixar imagem' : 'Baixar nota fiscal';
                const buttonDownload = vat.status === 15 ? {

                    button: () => getEtiquetaDevolucao(state.data.clifor, state.data.entrega),
                    buttonText: "Gerar etiqueta de devolução"
                } : {
                    button: () => download(vat.entrega, vat.clifor, downloadFileType, vat.idArquivo),
                    buttonText: downloadText
                }

                const button = statusAtual.autoMessage ? createAutoMessage(statusAtual.autoMessage, state.data?.atendente, h.createdAt) : buttonDownload;

                const messageTemplate = {
                    id: h.id,
                    title: `${new Date(h.createdAt).toLocaleDateString('pt-br')} - ${h?.realUser || h?.user}`,
                    message: <>Produto {prod?.produto} em {prod?.StatusGradeItem.type?.toUpperCase()} trocou
                        de <b {...s}>{san}</b> para <b {...s}>{sat}</b></>
                }

                return (vat.status === 11 || vat.status === 15 || vat.status === 17 || vat.status === 20 || statusAtual.autoMessage) ? { ...messageTemplate, ...button } : messageTemplate
            }
            return {
                id: h.idMensagem,
                title: `${new Date(h.createdAt).toLocaleDateString('pt-br')} - ${h.emissor}`,
                message: 'Mensagem customizada enviada',
                button: () => {
                    setTextMessage(h.mensagem)
                    setMessageInfo({
                        author: h.emissor,
                        date: `${new Date(h.createdAt).toLocaleDateString('pt-br')}`
                    })
                    setModalState("showMessage");
                },
                buttonText: 'Ver mensagem'
            }
        })
    }

    const propsChildrenStatus = {
        div: { style: { display: 'flex', justifyContent: 'space-between', width: '83%', marginBottom: '10px' } },
        radio: { style: { padding: '0 9px', marginRight: '12px', fontFamily: 'flama-book !important' } }
    }

    const modalFinalizarTitle = (
        <>
            <Warning /><br />
            <span>Tem certeza?</span>
        </>
    )

    const modalContent = {
        newMessage: <SomaTextEditor setText={setTextMessage} />,
        showMessage:
            <>
                <p className='p__acompanhamento--message-info'>Enviada por: {messageInfo.author}</p>
                <p className='p__acompanhamento--message-info'>Data: {messageInfo.date}</p>
                <div className="div__acompanhamento--message" dangerouslySetInnerHTML={{ __html: textMessage }} />
            </>,
        divergenciaStatus:
            <div style={{ lineHeight: 1.5 }}>
                Atualize o status de {divergeciaStatus.tipo.toUpperCase()} do produto {divergeciaStatus.produto}:
                <div {...propsChildrenStatus.div} >
                    <span>Tam: {divergeciaStatus.gradeItem}</span>
                    <span>Qtde: {divergeciaStatus.qtde}</span>
                    <span>Cor: {divergeciaStatus.cor}</span>
                </div>

                <RadioGroup aria-label="status" name="status" value={divergeciaStatus.status} onChange={handleChange}>
                    {Status.filter(s => s.id_status_parent === 4).map(s => {
                        const showOptions = s.show_to_divergency.includes(divergeciaStatus.tipo);
                        return showOptions ? (
                            <FormControlLabel
                                key={s.id_status}
                                value={s.id_status}
                                control={<Radio {...propsChildrenStatus.radio} />}
                                label={s.type}
                            />
                        ) : (
                            <></>
                        );
                    })}
                </RadioGroup>
            </div>
    }

    const modalProps = {
        none: { open: false },
        newMessage: {
            modalName: "ENVIAR MENSAGEM",
            hideButtons: false,
            closeName: "Voltar",
            onClose: () => setModalState("none"),
            actionName: "ENVIAR",
            open: modalState === "newMessage",
            action: () => {
                textMessage.trim() && salvarMensagem(state.data?.entrega, textMessage, state.data?.clifor, false)
                setModalState("none")
            },
            style: { width: '80%' }
        },
        showMessage: {
            modalName: "MENSAGEM ENVIADA",
            hideButtons: true,
            onClose: () => setModalState("none"),
            open: modalState === "showMessage",
        },
        divergenciaStatus: {
            modalName: "ATUALIZAR STATUS",
            closeName: "Voltar",
            onClose: () => setModalState("none"),
            actionName: "SALVAR",
            open: modalState === "divergenciaStatus",
            action: () => {
                atualizarStatus({
                    ...divergeciaStatus,
                    entrega: state.data.entrega,
                    clifor: state.data.clifor
                })
                setModalState("none")
            }
        }
    }

    return (
        state.loading ? <Spinner /> :
            <div className='containerBoxItems' style={{ marginTop: '100px' }}>
                <Modal {...modalProps[modalState]}>
                    {modalContent[modalState]}
                </Modal>

                <ButtonBack onClick={voltar} />

                <div className='containerNfeBoxItems'>
                    <CardBox {...propsCardBox} />

                    {!state.data.lockedAt && <PrimaryButton onClick={() => { setTextMessage(''); setModalState('newMessage') }}
                        style={{ width: '100%', margin: '10px 0' }}>
                        <SendMessage style={{ marginRight: '5px' }} />
                        ENVIAR MENSSAGEM
                    </PrimaryButton>}

                    <Historic {...propsHistoric} />
                </div>

                <DetailsBox list={list} />
                {!state.data.lockedAt && <FooterButton onClick={finishDivergency}>FINALIZAR ATENDIMENTO</FooterButton>}


                <ConfirmCredentialsModal
                    open={openModalFinalizar}
                    onClose={() => setOpenModalFinalizar(false)}
                    notInputs={true}
                    mainClassName={'main__contentmodal--container-2'}
                    buttonClassName={'buttonFooter'}
                    actionName={'FINALIZAR DIVERGÊNCIA'}
                    actionButton={() => finalizarAtendimento(state.data.entrega, state.data.clifor)}
                    title={modalFinalizarTitle}
                    subtitle={'Ao finalizar esta divergência, você não poderá mais fazer alterações nela.'}
                />
            </div >
    )
}

export default DivergenciasEntrega