import React from "react";

import whatsappImage from "../../../../../../assets/images/+Lojinha/whatsapp.svg";
import lojinhaPdf from "../../../../../../assets/pdf_to_download/BEM_VINDO_AO_+LOJINHA.pdf";

import "./index.css";

const Contact = () => (
  <section className="section__contact-container">
    <strong>
      Agora, você tem uma ferramenta poderosa para impulsinar suas vendas com
      código!
    </strong>
    <p>
      <a className="a__clickhere--contact" href={lojinhaPdf} download='BEM VINDO AO +LOJINHA'>
        Acesse aqui
      </a>{" "}
      a documentação do +Lojinha ou entre em contato com a nossa central de
      comunicação a Multimarca Afiliada:
    </p>
    <div>
      <img src={whatsappImage} alt="Logo WhatsApp" />
      <span>21 98565-1098</span>
    </div>
  </section>
);

export default Contact;
