import React from "react";

import FeaturesItems from "../FeaturesItems";

import './index.css'

const FeatureList = ({ lojinhaItems }) => (
  <ul className="ul__features--list">
    {lojinhaItems.items.map((item) => (
      <FeaturesItems key={item.title} {...item} />
    ))}
  </ul>
);

export default FeatureList;
