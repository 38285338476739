import React from "react";

import "./index.css";

const SocialMediaList = ({ socialMediaItems }) => (
  <ul className="ul__socialMedia-list">
    {socialMediaItems.map((item) => (
      <li key={item.alt}>
        <img src={item.img} alt={item.alt} />
      </li>
    ))}
  </ul>
);

export default SocialMediaList;
