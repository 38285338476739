import React, { useMemo } from "react";

import HomeHeader from "./HomeHeader/HomeHeader";
import DoYouKnow from "./+Lojinha/DoYouKnow";
import Feature from "./+Lojinha/Features";
import WhoCanAccess from "./+Lojinha/WhoCanAccess";
import Contact from "./+Lojinha/Contact";

import linkImage from "../../../../assets/images/+Lojinha/lojinha_link.svg";
import shareImage from "../../../../assets/images/+Lojinha/lojinha_share.svg";
import doorImage from "../../../../assets/images/+Lojinha/lojinha_door.svg";
import facebookImage from "../../../../assets/images/+Lojinha/facebook.svg";
import instagramImage from "../../../../assets/images/+Lojinha/instagram.svg";
import tiktokImage from "../../../../assets/images/+Lojinha/tiktok.svg";
import whatsappImage from "../../../../assets/images/+Lojinha/whatsapp.svg";

import "./HomeInfoContainer.css";

const HomeInfoContainer = () => {
  const itemsLojinha = useMemo(
    () => ({
      items: [
        {
          title: "TUDO EM UM SÓ LUGAR",
          description: (
            <p>
              Não é preciso lembrar de uma nova senha nem login. Você acessa o
              +Lojinha direto do +Vendas!{" "}
              <a
                className="a__clickhere"
                href="https://maisvendas.somalabs.com.br/login"
                target="_blank"
                rel="noopener noreferrer"
              >
                Clique aqui
              </a>{" "}
              para acessar!
            </p>
          ),
          img: doorImage,
        },
        {
          title: "CÓDIGO DIRETO NA SACOLA",
          description:
            "Chega de esquecerem de colocar o código vendedor na compra. Ou de ter que ensinar como e onde colocar. Por meio da sua lojinha, a peça que for adicionada ao carrinho dos nossos ecommerces já conectará, automaticamente, o seu código vendedor à compra.",
          img: linkImage,
        },
        {
          title: "SUPER COMPARTILHÁVEL",
          description:
            "É possível compartilhar o link da sua lojinha nas principais redes sociais e plataformas de entretenimento. ",
          img: shareImage,
        },
      ],
    }),
    []
  );

  const socialMediaItems = useMemo(
    () => [
      { img: instagramImage, alt: "Instagram" },
      { img: whatsappImage, alt: "WhatsApp" },
      { img: tiktokImage, alt: "Tik Tok" },
      { img: facebookImage, alt: "Facebook" },
    ],
    []
  );

  return (
    <div className="div__HomeInfo--container" data-testid="homeInfoContainer">
      <HomeHeader />
      <DoYouKnow />
      <p className="p__lojinha--text">
        O +Lojinha permite criar vitrines virtuais com seleções personalizadas
        de produtos e compartilhar em todas as redes. Vamos conhecer?
      </p>
      <Feature
        lojinhaItems={itemsLojinha}
        socialMediaItems={socialMediaItems}
      />
      <WhoCanAccess />
      <Contact />
    </div>
  );
};

export default HomeInfoContainer;
