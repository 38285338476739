import React from "react";

import './index.css'

const FeaturesItems = ({ title, description, img }) => {
  return (
    <li className="li__featureitems--container">
      <img src={img} alt={title} />
      <div className="div__featureitems--informations">
        <h2>{title}</h2>
        {typeof description === "string" ? <p>{description}</p> : description}
      </div>
    </li>
  );
};

export default FeaturesItems;
