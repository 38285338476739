import React, { useEffect, useState } from 'react';
import { differenceInDays } from 'date-fns';

import SomaStatus from '../../../components/SomaStandard/SomaStatus'
import ItemsRow from '../../../components/ItemsRow/ItemsRow';

import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { authFetch } from '../../../helpers/authHelper';
import MaisAtacado from '../../../Services/MaisAtacado/MaisAtacado';
import handleApi from '../../../helpers/apiHelper';
import { trackingEvent, trackingPageview } from '../../../Services/GoogleAnalytics';

const SaibaMais = () => {
    const [results, setResults] = useState([])
    const [status, setStatus] = useState([])

    const downloadBtn = async (material) => {
        let file
        if (material.infos.type === 'link') {
            return
        } else {
            file = await handleApi(() => MaisAtacado.get(`/v1/material/getFile?idArquivo=${encodeURI(material.idArquivo)}&type=${encodeURI(material.infos.type)}`
                , { responseType: 'blob' }
            ))
            if (file.error) return

            file = URL.createObjectURL(file.data);
        }

        const a = document.createElement("a");
        a.href = file;
        a.download = material.idArquivo;
        a.click();
    }

    const openBtn = async (material) => {
        let file
        if (material.infos.type === 'link') {
            file = material.idArquivo
        }
        else {
            file = await handleApi(() => MaisAtacado.get(`/v1/material/getFile?idArquivo=${encodeURI(material.idArquivo)}&type=${encodeURI(material.infos.type)}`
                , { responseType: 'blob' }
            ))
            if (file.error) return
            file = URL.createObjectURL(await file.data);
        }

        const a = document.createElement("a");
        a.href = file;
        a.target = '_blank';
        a.click();
    }

    const rodape = () => {
        return <>
            <p style={{ fontSize: '12px' }}>
                <span style={{ marginRight: window.innerWidth > 480 ? '20px' : '0', display: window.innerWidth > 480 ? 'inline' : 'block' }}>
                    <EmailIcon fontSize='small' />
                    <span style={{ verticalAlign: 'super', marginLeft: '3px' }}>atendimentomultimarcas@somagrupo.com.br</span>
                </span>
                <span>
                    <WhatsAppIcon fontSize='small' />
                    <span style={{ verticalAlign: 'super', marginLeft: '3px' }}>+55 21 3733-7708</span>
                </span>
            </p >
        </>
    }

    useEffect(() => {
        setStatus('wait');
        (async () => {
            try {
                console.log('Fazendo tracking')
                trackingPageview()
                trackingEvent()

                const compararDatas = (date) => differenceInDays(new Date(), new Date(date))

                const json = await (await authFetch(`/v1/material/`)).json();

                Object.entries(json).forEach(([titulo]) => {
                    json[titulo].forEach((material, index) => {
                        json[titulo][index] = {
                            ...material,
                            rodape: rodape(),
                            tag: {
                                name: 'Novo',
                                color: '#707070',
                                Show: compararDatas(material.createdAt) > 0 && compararDatas(material.createdAt) < 7
                            },
                            leftBtn: {
                                onClick: () => openBtn(material),
                                name: 'ABRIR',
                                style: { backgroundColor: material.infos.thumb }
                            },
                            rightBtn: {
                                onClick: () => downloadBtn(material),
                                name: 'DOWNLOAD',
                                disabled: material.infos.type === 'link',
                                style: { color: material.infos.thumb, borderColor: material.infos.thumb }
                            }
                        }
                    })
                });

                setResults(json);
                setStatus(Object.keys(json)?.length ? 'result' : 'empty');
            } catch (err) {
                setResults(undefined);
                setStatus('error');
            }
        })();
    }, []);

    return (
        <SomaStatus status={status}>
            {
                Object.entries(results).map(([key, value]) => {
                    return (<ItemsRow key={key} widthCard={184} title={key} items={value} />)
                })
            }
        </SomaStatus>
    )
}

export default SaibaMais;