import React, { useCallback } from 'react'
import { motion } from 'framer-motion'

import CloseBtnImg from '../../assets/images/icons/close_btn.svg'

import './Popup.css'

const Popup = props => {

    const { open, onClose, children, width } = props;

    const animationModalVariants = {
        open: {
            display: "flex",
            opacity: 1,
            transition: {
                duration: 0.5
            }
        },
        closed: {
            transitionEnd: { display: "none" },
            opacity: 0,
            transition: {
                duration: 0.2
            }
        }
    };

    const onClickBackdropHandler = useCallback((event) => {
        if (event.target.id === 'backdrop') {
            onClose()
        }
    }, [onClose])

    return (
        <motion.div data-testid="popup" className="div__backdrop--container_popup" id="backdrop" variants={animationModalVariants} animate={open ? 'open' : 'closed'} onClick={(e) => onClickBackdropHandler(e)}>
            <div className="div__popup__container">
                <img data-testid="imgClosePopup" src={CloseBtnImg} alt="Botão de fechar" className="img__btnclose--container_popup" onClick={onClose} />
                <main className="main__contentpopup--container" style={{ width: width }}>
                    <div className="div__popup-container">
                        {children}
                    </div>
                </main>
            </div>
        </motion.div>
    )
}


export default Popup