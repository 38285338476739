import ReactGA from "react-ga4";

ReactGA.initialize("G-27VXCGSFDW");

const trackingPageview = () => {
    ReactGA.send({ hitType: "pageview", page: "/Material/saiba-mais", title: "materiais" });
}

const trackingEvent = (category, action, label) => {
    ReactGA.event({
        category: "material Atacado teste",
        action: "clique de download",
        label: "material teste",
    });
}

export { trackingPageview, trackingEvent }
