import React from "react";

import AtacadoMenu from "../../../../../../assets/images/+Lojinha/maisAtacado_menu.svg";

import "./index.css";

const WhoCanAccess = () => (
  <section className="section__whocanacess--container">
    <div className="div__whocanacess--informations">
      <h4>QUEM PODE ACESSSAR?</h4>
      <p>
        Qualquer vendedor(a) com código associado a uma Multimarca Afiliada pode
        utilizar todos os serviços do +Lojinha.
        <br />
        <br /> Caso ainda não seja seja Afiliada,{" "}
        <a className="a__clickhere--feature" href="/Afiliado/cadastro">
          Clique aqui
        </a>{" "}
        para se cadastrar ou inicie o processo no menu lateral ao lado.
      </p>
    </div>
    <img src={AtacadoMenu} alt="Mais atacado menu" />
  </section>
);

export default WhoCanAccess;
